<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar :title="headline" :showBackButton="true" :buttons="toolbarButtons" @onButton="onToolbarButton"></app-view-toolbar>
      <!--
      <app-view-toolbar :title="headline" :showBackButton="true" :buttons="toolbarButtons" @onButton="onToolbarButton" :tabs="toolbarTabs" @onTab="onToolbarTab"></app-view-toolbar>
      -->
    </v-container>
    <v-container class="px-6">
      <!--
      <v-tabs-items v-model="toolbarTab">
        <v-tab-item>
      -->
          <v-form
            ref="formCustomer"
            lazy-validation
          >
            <v-row class="pt-6">
              <v-col cols="12" class="py-0">
                <v-btn-toggle
                  v-model="customer.cst_enm_type"
                  class="mb-8"
                  color="blue darken-4"
                  mandatory
                >
                  <v-btn
                    value="private"
                    text
                    small
                  >
                    Privat
                  </v-btn>
                  <v-btn
                    value="company"
                    text
                    small
                  >
                    Företag
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="8" class="pt-0 pb-4">
                <v-card outlined color="grey lighten-3" rounded="lg" class="mb-4">
                  <v-card-title>
                    <div class="text-subtitle-2 mb-2">Allmänt</div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          label="Namn"
                          v-model="customer.cst_str_name"
                          dense
                          outlined
                          background-color="white"
                          maxlength="50"
                          :rules="[rules.required]"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Er referens"
                          v-model="customer.cst_str_your_reference"
                          dense
                          outlined
                          background-color="white"
                          maxlength="50"
                        >
                        </v-text-field>
                        <v-select
                          label="Ansvarig säljare"
                          v-model="customer.cst_id_usr_seller"
                          dense
                          outlined
                          :items="sellers"
                          background-color="white"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          :label="organizationNumberAttr.label"
                          v-model="customer.organizationNumber"
                          dense
                          :placeholder="organizationNumberAttr.placeholder"
                          persistent-placeholder
                          outlined
                          background-color="white"
                          :maxlength="organizationNumberAttr.maxlength"
                          :rules="organizationNumberAttr.rules"
                        >
                        </v-text-field>
                        <v-text-field
                          :label="customerNumberLabel"
                          v-model="customer.cst_str_customer_number"
                          dense
                          outlined
                          background-color="white"
                          maxlength="35"
                          :disabled="!customerNumberIsEditable"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card outlined color="grey lighten-3" rounded="lg" class="mb-4">
                  <v-card-title>
                    <div class="text-subtitle-2 mb-2">Kontakt</div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          label="Telefon 1"
                          v-model="customer.cst_str_phone_1"
                          dense
                          outlined
                          background-color="white"
                          maxlength="25"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Telefon 2"
                          v-model="customer.cst_str_phone_2"
                          dense
                          outlined
                          background-color="white"
                          maxlength="25"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          label="E-post"
                          v-model="customer.cst_str_email"
                          dense
                          outlined
                          background-color="white"
                          maxlength="50"
                          :rules="[rules.email]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card outlined color="grey lighten-3" rounded="lg" class="mb-4">
                  <v-card-title>
                    <div class="text-subtitle-2 mb-2">Fakturainställningar</div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          label="E-post för faktura"
                          v-model="customer.cst_str_email_invoice"
                          dense
                          outlined
                          background-color="white"
                          maxlength="50"
                          :rules="[rules.email]"
                        >
                        </v-text-field>
                        <v-switch
                          v-model="customer.cst_bit_free_shipping"
                          label="Fraktfritt"
                          class="mt-0"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-select
                          label="Betalningsvillkor"
                          v-model="customer.cst_enm_terms_of_payment"
                          dense
                          outlined
                          :items="termsOfPayments"
                          background-color="white"
                          :rules=[rules.required]
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card outlined color="grey lighten-3" rounded="lg" class="mb-4">
                  <v-card-title>
                    <div class="text-subtitle-2 mb-2">Fakturaadress</div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          label="Gatuadress"
                          v-model="customer.cst_str_street"
                          dense
                          outlined
                          background-color="white"
                          maxlength="50"
                          :rules="customer.cst_enm_general ? [] : [rules.required]"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Gatuadress 2"
                          v-model="customer.cst_str_street_2"
                          dense
                          outlined
                          background-color="white"
                          maxlength="50"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          label="Postnummer"
                          v-model="customer.cst_str_zip"
                          dense
                          outlined
                          background-color="white"
                          maxlength="15"
                          :rules="customer.cst_enm_general ? [] : [rules.required]"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Postort"
                          v-model="customer.cst_str_city"
                          dense
                          outlined
                          background-color="white"
                          maxlength="30"
                          :rules="customer.cst_enm_general ? [] : [rules.required]"
                        >
                        </v-text-field>
                        <v-select
                          label="Land"
                          v-model="customer.cst_id_cnt"
                          dense
                          outlined
                          :items="countries"
                          background-color="white"
                          :rules="customer.cst_enm_general ? [] : [rules.required]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!--custom fields-->
                <v-card v-if="customFields.length" outlined color="grey lighten-3" rounded="lg" class="mb-4">
                  <v-card-text>
                    <div v-for="customField of customFields" :key="customField.label">
                      <template v-if="customField.type == 'chip-group'">
                        <div class="text-subtitle-2 mb-1" v-text="customField.title"></div>
                        <v-chip-group
                          :mandatory="customField.mandatory"
                          active-class="primary--text"
                          v-model="customer[customField.column]"
                          column
                          class="mb-4"
                        >
                          <v-chip
                            v-for="customFieldOption in customField.options"
                            :key="customFieldOption.value"
                            :value="customFieldOption.value"
                            v-text="customFieldOption.text"
                          >
                          </v-chip>
                        </v-chip-group>
                      </template>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card outlined color="grey lighten-3" rounded="lg" class="mb-4">
                  <v-card-title>
                    <div class="text-subtitle-2 mb-0">Övrigt</div>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                      v-model="customer.cst_str_important_note"
                      label="Viktig information"
                      dense
                      outlined
                      rows="3"
                      background-color="white"
                    >
                    </v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <template v-if="isCgrSelectable">
                  <div class="text-subtitle-2 mb-2">Kundgrupp</div>
                  <v-radio-group
                    v-model="customer.cst_id_cgr"
                    mandatory
                    class="mt-0"
                  >
                    <v-radio
                      v-for="customerGroup of customerGroups" :key="customerGroup.value"
                      :label="customerGroup.text"
                      :value="customerGroup.value"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </v-col>
            </v-row>
            <!--assignment of general type-->
            <template v-if="shouldShowGeneralTypeAssignment">
              <div
                class="text-subtitle-2 mb-1 mt-2"
              >
                <template v-if="isNew">
                  Special
                </template>
                <template v-else>
                  Special:
                  {{ customer.typeName }}
                  <v-btn
                    small
                    @click="showGeneralTypeButtons = !showGeneralTypeButtons"
                    text
                  >Ändra</v-btn>
                </template>
              </div>
              <v-chip-group
                v-if="shouldShowGeneralTypeChips"
                :mandatory="false"
                active-class="primary--text"
                v-model="customer.cst_enm_general"
                column
                class="mb-4"
              >
                <v-chip
                  v-for="generalType in availableGeneralTypes"
                  :key="generalType.value"
                  :value="generalType.value"
                >
                  {{ generalType.label }}
                </v-chip>
              </v-chip-group>
            </template>
            <v-btn
              outlined
              color="red"
              class="mt-8"
              @click="deleteCustomer"
              v-if="showDeleteButton"
              :disabled="!isDeletable"
              small
            >
              <v-icon left>mdi-trash-can-outline</v-icon>
              Ta bort kunden
            </v-btn>
            <p class="text-caption mt-2 red--text text--darken-2" v-if="unDeletableMessage.length" v-text="unDeletableMessage"></p>
          </v-form>
        <!--
        </v-tab-item>
        <v-tab-item>
        -->
          <v-row class="py-8">
            <v-col cols="12">
              <div class="d-flex">
                <v-switch
                  v-model="customer.hasSpecificDeliveryAddress"
                  label="Avvikande leveransadress"
                  v-if="isNew"
                  class="mt-0"
                ></v-switch>
                <div
                  class="text-subtitle-2 mb-4"
                  v-else
                >Leveransadresser</div>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialogEditDeliveryAddress"
                  :fullscreen="useFullscreenDialog"
                  :max-width="useFullscreenDialog ? 10000 : 600"
                  v-if="!shouldUseBillingAddressAsDeliveryAddress"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      text
                      small
                    >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Lägg till
                    </v-btn>
                  </template>
                  <v-card
                    class="grey lighten-3"
                  >
                    <v-card-title>
                      <span class="text-h6">{{ dialogEditDeliveryAddressTitle }}</span>
                      <v-spacer></v-spacer>
                      <v-icon
                        @click.stop="closeEditDeliveryAddress"
                      >
                        mdi-close
                      </v-icon>
                    </v-card-title>
                    <v-card-text
                      class="pb-0"
                    >
                      <v-form
                        ref="formDeliveryAddress"
                        lazy-validation
                      >
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              class="pa-0"
                            >
                              <v-text-field
                                v-model="editedDeliveryAddress.cda_str_name"
                                label="Namn"
                                dense
                                outlined
                                maxlength="50"
                                background-color="white"
                                :rules="[rules.required]"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedDeliveryAddress.cda_str_street"
                                label="Gatuadress"
                                dense
                                outlined
                                maxlength="50"
                                background-color="white"
                                :rules="[rules.required]"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedDeliveryAddress.cda_str_street_2"
                                label="Gatuadress 2"
                                dense
                                outlined
                                background-color="white"
                                maxlength="50"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedDeliveryAddress.cda_str_zip"
                                label="Postnummer"
                                dense
                                outlined
                                maxlength="15"
                                background-color="white"
                                :rules="[rules.required]"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedDeliveryAddress.cda_str_city"
                                label="Postort"
                                dense
                                outlined
                                maxlength="30"
                                background-color="white"
                                :rules="[rules.required]"
                              ></v-text-field>
                              <v-select
                                label="Land"
                                v-model="editedDeliveryAddress.cda_id_cnt"
                                dense
                                outlined
                                :items="countries"
                                background-color="white"
                                :rules="[rules.required]"
                              >
                              </v-select>
                              <v-switch
                                v-model="editedDeliveryAddress.cda_bit_splitted_packing_list_tables"
                                label="Dela upp plocksedlar på maskin"
                                class="mt-0"
                              ></v-switch>
                              <template v-if="stockPoints.length">
                                <!--stockPoints-->
                                <div class="text-subtitle-2 mb-1">Lagerställe</div>
                                <v-chip-group
                                  :mandatory="false"
                                  active-class="primary--text"
                                  v-model="editedDeliveryAddress.cda_id_spn"
                                  column
                                  class="mb-4"
                                >
                                  <v-chip
                                    v-for="stockPoint in stockPoints"
                                    :key="stockPoint.spn_id"
                                    :value="stockPoint.spn_id"
                                    v-text="stockPoint.spn_str_name"
                                  >
                                  </v-chip>
                                </v-chip-group>
                              </template>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="red"
                        @click="deleteDeliveryAddress"
                        v-if="!isAddingNewDeliveryAddress"
                      >
                        Ta bort
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="saveDeliveryAddress"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <template v-if="!shouldUseBillingAddressAsDeliveryAddress">
                <v-data-table
                  :headers="deliveryAddressData.headers"
                  :items="deliveryAddressData.items"
                  no-data-text="Inga leveransadresser finns upplagda"
                  :disable-pagination="true"
                  hide-default-footer
                  @click:row="editDeliveryAddress"
                  class="click-table"
                >
                  <template v-slot:[`item.prf_count`]="{ item }">
                    {{ item.prf_count }} st
                  </template>
                  <!-- <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      @click.stop="deleteDeliveryAddress(item)"
                      color="red"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </template> -->
                </v-data-table>
              </template>
              <template v-if="showGeneralStockPointSelector">
                <!--stockPoints-->
                <div class="text-subtitle-2 mb-1">Lagerställe</div>
                <v-chip-group
                  :mandatory="false"
                  active-class="primary--text"
                  v-model="customer.general_spn_id"
                  column
                  class="mb-4"
                >
                  <v-chip
                    v-for="stockPoint in stockPoints"
                    :key="stockPoint.spn_id"
                    :value="stockPoint.spn_id"
                    v-text="stockPoint.spn_str_name"
                  >
                  </v-chip>
                </v-chip-group>
              </template>
            </v-col>
          </v-row>
      <!--
        </v-tab-item>
      </v-tabs-items>
      -->
    </v-container>
    <!--confirm for deleting customer-->
    <app-confirm :show="showDeleteCustomerConfirm" title="Ta bort kunden?" @onYes="executeDeleteCustomer" @onNo="showDeleteCustomerConfirm = false"></app-confirm>
    <!--confirm for deleting delivery address-->
    <app-confirm :show="showDeleteDeliveryAddressConfirm" title="Ta bort leveransadressen?" @onYes="executeDeleteDeliveryAddress" @onNo="showDeleteDeliveryAddressConfirm = false"></app-confirm>
  </div>
</template>

<script>
import { onMounted, computed, ref, reactive, watch } from '@vue/composition-api'
import useCore from '@/helpers/core'
import useValidation from '@/helpers/validation'
import ViewToolbar from '@/components/ViewToolbar.vue'
import Confirm from '@/components/Confirm.vue'

export default {
  name: 'Customer',
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-confirm': Confirm,
  },
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const store = require('@/store/store').default;
    const router = require('@/router/router').default;

    const loggedInData = computed(() => store.getters.loggedInData);

    const isKaxig = loggedInData.value.isKaxig;

    const useFullscreenDialog = store.getters.useFullscreenDialog;

    const { callApi, showMessage, setProgress, tellKobra } = useCore();
    const { rules, restoreInteger } = useValidation();

    const headline = ref(null);
    const dataLoaded = ref(false);

    const customer = ref(null);

    const id = props.id;
    const isNew = id == 0;
    const showDeleteButton = !isNew;
    const cdaIds_remove = ref([]);
    const countries = ref([]);
    const termsOfPayments = ref([]);
    const sellers = ref([]);
    const customerGroups = ref([]);
    const formCustomer = ref(null);
    const formDeliveryAddress = ref(null);
    const dialogEditDeliveryAddress = ref(false);
    const showDeleteDeliveryAddressConfirm = ref(false);
    const showDeleteCustomerConfirm = ref(false);
    const unDeletableMessage = ref('');
    const customerNumberIsEditable = ref(null);
    const customerNumberLabel = ref('');
    const customFields = ref([]);
    const stockPoints = ref([]);
    const availableGeneralTypes = ref([]);
    const showGeneralTypeButtons = ref(isNew);
    const isCgrSelectable = ref(false);

    const editedDeliveryAddressIndex = ref(-1);
    const editedDeliveryAddress = ref({
      cda_id: 0,
      cda_str_name: '',
      cda_str_street: '',
      cda_str_street_2: '',
      cda_str_zip: '',
      cda_str_city: '',
      cda_id_cnt: 1,
      cda_id_spn: null,
      cda_bit_splitted_packing_list_tables: false,
      prf_count: 0,
    });

    const defaultItem = {
      cda_id: 0,
      cda_str_name: '',
      cda_str_street: '',
      cda_str_street_2: '',
      cda_str_zip: '',
      cda_str_city: '',
      cda_id_cnt: 1,
      cda_id_spn: '',
      cda_bit_splitted_packing_list_tables: false,
      prf_count: 0,
    }

    const organizationNumberAttr = computed(() => {
      if (customer.value.cst_enm_type === 'private') {
        return {
          label: 'Personnummer',
          placeholder: 'ÅÅÅÅMMDD-NNNN',
          rules: customer.cst_enm_general ? [rules.personNumber] : [rules.required, rules.personNumber],
          maxlength: 13,
        }
      }
      return {
        label: 'Organisationsnummer',
        placeholder: countryDependantOrgNoAttr.value.syntax,
        // rules: countryDependantOrgNoAttr.value.rules,
        rules: [],// TODO: different rules depending on country
        maxlength: 15,
      }
    });

    const sweden = computed(() => {
      return countries.value.find(country => country.value == 1);
    });

    const selectedCountry = computed(() => {
      return countries.value.find(country => country.value == customer.value.cst_id_cnt);
    });

    const countryDependantOrgNoAttr = computed(() => {
      let patternString, syntax;
      if (selectedCountry.value) {
        // read from selected country
        patternString = selectedCountry.value.orgno_pattern;
        syntax = selectedCountry.value.orgno_syntax;
      } else if (sweden.value) {
        // use sweden as fallback
        patternString = sweden.value.orgno_pattern;
        syntax = sweden.value.orgno_syntax;
      }
      if (patternString) {
        const pattern = new RegExp(patternString);
        const orgNoRule = value => {
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || `Ange i formatet ${syntax}`;
        }
        return {
          rules: customer.cst_enm_general ? [orgNoRule] : [rules.required, orgNoRule],
          syntax
        }
      }
      return {
        rules: [],
        syntax: ''
      };
    });

    const shouldUseBillingAddressAsDeliveryAddress = computed(() => {
      return isNew && !customer.value.hasSpecificDeliveryAddress;
    });

    const showGeneralStockPointSelector = computed(() => {
      return isNew && !customer.value.hasSpecificDeliveryAddress && stockPoints.value.length;
    });

    const dialogEditDeliveryAddressTitle = computed(() => {
      return editedDeliveryAddressIndex.value === -1 ? 'Ny leveransadress' : 'Redigera leveransadress'
    });

    const isAddingNewDeliveryAddress = computed(() => {
      return editedDeliveryAddressIndex.value === -1;
    });

    const deliveryAddressIsRequired = computed(() => {
      if (isNew) {
        return customer.value.hasSpecificDeliveryAddress && !deliveryAddressData.items.length;
      }
      return !customer.value.cst_enm_general && !deliveryAddressData.items.length;
    });

    const isDeletable = computed(() => {
      return dataLoaded.value && customer.value.prf_count == 0;
    });

    const shouldShowGeneralTypeAssignment = computed(() => {
      return isKaxig && availableGeneralTypes.value.length;
    });

    const shouldShowGeneralTypeChips = computed(() => {
      return shouldShowGeneralTypeAssignment.value && showGeneralTypeButtons.value;
    });
    
    const deliveryAddressData = reactive({
      headers: [],
      items: [],
    });

    const onData = (_referer, response) => {
      console.log('onData', response);
      customer.value = response.customer;
      headline.value = id == 0 ? 'Ny kund' : customer.value.cst_str_name;
      countries.value = response.countries;
      termsOfPayments.value = response.termsOfPayments;
      sellers.value = response.sellers;
      if (response.customerGroups) {
        isCgrSelectable.value = true;
        customerGroups.value = response.customerGroups;
      }
      dataLoaded.value = true;
      deliveryAddressData.headers = response.deliveryAddressData.headers;
      deliveryAddressData.items = response.deliveryAddressData.items;
      customerNumberIsEditable.value = response.customerNumberIsEditable;
      customerNumberLabel.value = response.customerNumberLabel;
      availableGeneralTypes.value = response.availableGeneralTypes;
      if (response.customFields) {
        customFields.value = response.customFields;
      }
      if (response.stockPoints) {
        stockPoints.value = response.stockPoints;
      }
      if (customer.value.prf_count != 0) {
        let txt_profiles = customer.value.prf_count == 1 ? 'profil' : 'profiler';
        unDeletableMessage.value = 'Kunden kan inte tas bort då den används av ' + customer.value.prf_count + ' ' + txt_profiles;
      }
    };

    const onToolbarButton = button => {
      switch (button.label) {
        case 'saveCustomer':
          saveCustomer();
        break;
      }
    }

    // const toolbarTabs = [
    //   {
    //     label: 'customer',
    //     text: 'Kunduppgifter',
    //   },
    //   {
    //     label: 'deliveryAddresses',
    //     text: 'Leveransadresser',
    //   }
    // ];

    // const toolbarTab = ref(toolbarTabs[0]);

    // const onToolbarTab = tab => {
    //   toolbarTab.value = tab;
    // }

    watch(() => dialogEditDeliveryAddress.value, newValue => {
      newValue || closeEditDeliveryAddress();
    });

    const editDeliveryAddress = item => {
      editedDeliveryAddressIndex.value = deliveryAddressData.items.indexOf(item);
      editedDeliveryAddress.value = Object.assign({}, item);
      // console.log('editedDeliveryAddress', editedDeliveryAddress.value);
      dialogEditDeliveryAddress.value = true;
      tellKobra('scrollToTop');
    }

    const deleteDeliveryAddress = () => {
      showDeleteDeliveryAddressConfirm.value = true;
    }

    // const deleteDeliveryAddress = item => {
    //   editedDeliveryAddressIndex.value = deliveryAddressData.items.indexOf(item);
    //   editedDeliveryAddress.value = Object.assign({}, item);
    //   showDeleteDeliveryAddressConfirm.value = true;
    // }

    const executeDeleteDeliveryAddress = () => {
      const cdaId = deliveryAddressData.items[editedDeliveryAddressIndex.value].cda_id;
      if (cdaId != 0) {
        // if cdaId is not 0, we push to cdaIds_remove in order to remove it server side on save
        cdaIds_remove.value.push(cdaId);
        // console.log('cdaIds_remove', cdaIds_remove.value);
      }
      deliveryAddressData.items.splice(editedDeliveryAddressIndex.value, 1);
      closeEditDeliveryAddress();
      showDeleteDeliveryAddressConfirm.value = false;
    }

    const closeEditDeliveryAddress = () => {
      dialogEditDeliveryAddress.value = false;
      editedDeliveryAddress.value = Object.assign({}, defaultItem);
      editedDeliveryAddressIndex.value = -1;
    }

    const saveDeliveryAddress = () => {
      const formIsValid = formDeliveryAddress.value.validate();
      if (!formIsValid) {
        showMessage('Formuläret är inte komplett ifyllt');
        return;
      }
      // set spn_str_name depending on cda_id_spn
      editedDeliveryAddress.value.spn_str_name = editedDeliveryAddress.value.cda_id_spn ? stockPoints.value.find(stockPoint => stockPoint.spn_id == editedDeliveryAddress.value.cda_id_spn).spn_str_name : '';
      console.log('editedDeliveryAddress', editedDeliveryAddress.value);
      if (editedDeliveryAddressIndex.value > -1) {
        Object.assign(deliveryAddressData.items[editedDeliveryAddressIndex.value], editedDeliveryAddress.value);
      } else {
        deliveryAddressData.items.push(editedDeliveryAddress.value);
      }
      closeEditDeliveryAddress();
      formDeliveryAddress.value.resetValidation();
    }

    const deleteCustomer = () => {
      showDeleteCustomerConfirm.value = true;
    }

    const executeDeleteCustomer = () => {
      showDeleteCustomerConfirm.value = false;
      setProgress(true, 'Tar bort kund');
    
      callApi({
        method: 'delete',
        path: '/cms/customers/' + id,
      })
        .then(() => {
          setProgress();
          router.replace({ name: 'customerAll' });
        })
        .catch((err) => {
          setProgress();
          console.log(err);
          // showMessage(err, null, 'putOrder');
        });
    }

    const saveCustomer = () => {
      const formIsValid = formCustomer.value.validate();
      if (!formIsValid) {
        showMessage('Formuläret är inte komplett ifyllt');
        return;
      }
      if (deliveryAddressIsRequired.value) {
        showMessage('Minst en leveransadress måste anges');
        return;
      }
      setProgress(true, 'Sparar kund');
      const apiData = {
        customer: customer.value,
        deliveryAddresses: deliveryAddressData.items,
        cdaIds_remove: cdaIds_remove.value,
        customFields: customFields.value,
      }
    
      callApi({
        method: 'post',
        path: '/cms/customers/' + id,
        data: apiData,
      })
        .then(() => {
          setProgress();
          router.replace({ name: 'customerAll' });
        })
        .catch((err) => {
          setProgress();
          console.log(err);
        });
    }

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: '/cms/customers/' + id,
      })
        .then((apiResponse) => {
          onData('mounted', apiResponse);
        })
        .catch((err) => {
          return err;
        });
    };

    const toolbarButtons = computed(() => {
      const buttons = [];
      if (dataLoaded.value) {
        buttons.push({
          label: 'saveCustomer',
          icon: 'check',
          name: 'Spara',
        });
      }
      return buttons;
    });

    onMounted(doOnMounted);

    return {
      headline,
      toolbarButtons,
      onToolbarButton,
      dataLoaded,
      customer,
      countries,
      termsOfPayments,
      sellers,
      customerGroups,
      formCustomer,
      formDeliveryAddress,
      rules,
      restoreInteger,
      // toolbarTabs,
      // toolbarTab,
      // onToolbarTab,
      deliveryAddressData,
      showDeleteDeliveryAddressConfirm,
      dialogEditDeliveryAddress,
      dialogEditDeliveryAddressTitle,
      editDeliveryAddress,
      deleteDeliveryAddress,
      executeDeleteDeliveryAddress,
      editedDeliveryAddress,
      closeEditDeliveryAddress,
      saveDeliveryAddress,
      isDeletable,
      showDeleteButton,
      deleteCustomer,
      unDeletableMessage,
      executeDeleteCustomer,
      showDeleteCustomerConfirm,
      customerNumberIsEditable,
      customerNumberLabel,
      availableGeneralTypes,
      useFullscreenDialog,
      customFields,
      stockPoints,
      isNew,
      showGeneralTypeButtons,
      shouldShowGeneralTypeAssignment,
      shouldShowGeneralTypeChips,
      isCgrSelectable,
      isAddingNewDeliveryAddress,
      shouldUseBillingAddressAsDeliveryAddress,
      showGeneralStockPointSelector,
      organizationNumberAttr,
      isKaxig,
    }
  },
};
</script>

<style>

</style>